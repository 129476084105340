import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg';
import { CustomFormsModule } from 'ngx-custom-validators';
import { MaterialModule } from './material/material.module';
import { PageNotFoundComponent } from './404/page-not-found.component';
import { ApiService } from '@central/ng-shared';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { DashCardComponent } from './dash-card/dash-card.component';
import { NetworkValidationService } from './network-validation/network-validation.service';
import { FailedRequestComponent } from './error/failed-request.component';
import { InvalidInputService } from './forms/invalid-input.service';
import { LayoutBaseComponent } from './layout-base/layout-base.component';
import { NotificationService } from './notification/notification.service';
import { ProgressButtonComponent } from './progress-button/progress-button.component';
import { UserEchoService } from './userecho/userecho.service';
import { BrandingService } from '@central/ng-shared';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { ProjectSelectorComponent } from './project-selector/project-selector.component';
import { TextCopyComponent } from './text-copy/text-copy.component';
import { HelpDialogComponent } from './dash-card/help-dialog/help-dialog.component';


import { MomentModule } from 'ngx-moment';

/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */
@NgModule({
	imports: [
		CommonModule,
		MaterialModule,
		RouterModule,
		InlineSVGModule.forRoot(),
		FormsModule,
		ReactiveFormsModule,
		CustomFormsModule,
		MomentModule,
	],
	declarations: [
		PageNotFoundComponent,
		LayoutBaseComponent,
		FailedRequestComponent,
		DeleteDialogComponent,
		DashCardComponent,
		ProgressButtonComponent,
		EditDialogComponent,
		HeaderLogoComponent,
		ProjectSelectorComponent,
		TextCopyComponent,
		HelpDialogComponent,
	],
	exports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		InlineSVGModule,
		LayoutBaseComponent,
		ProgressButtonComponent,
		FailedRequestComponent,
		EditDialogComponent,
		DashCardComponent,
		PageNotFoundComponent,
		DeleteDialogComponent,
		HeaderLogoComponent,
		CustomFormsModule,
		ProjectSelectorComponent,
		TextCopyComponent,
		HelpDialogComponent,
	],
	providers: [
		ApiService,
		InvalidInputService,
		UserEchoService,
		BrandingService,
		NotificationService,
		NetworkValidationService,
	],
})
export class SharedModule {
	public static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
		};
	}
}
