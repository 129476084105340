<div [class]="accountInfo.hasOwnProperty('payments') ? 'ctw-px-8' : ''">
    <mat-radio-group aria-label="Select an option" [(ngModel)]="this.cardId" (change)="selectPaymentMethod()"
        class="ctw-flex" [class]="accountInfo.hasOwnProperty('payments') ? 'ctw-flex-col ctw-items-start' : ''">
        <mat-radio-button class="" value="new" *ngIf="!this.ampOpApiService.isAuthenticated() && (this.allowCheck === true || this.allowPaypal === true)"
        [class]="(this.allowCheck||this.allowPaypal) ? 'ctw-mr-5' : ''">
            <span>Credit Card</span>
        </mat-radio-button>
        
        <ng-container *ngIf="accountInfo.hasOwnProperty('payments')">
            <mat-radio-button class="ctw-mb-3 ctw-w-full amp-card" [value]="card.Id"
                *ngFor="let card of this.existingCards">
                <div class="ctw-grid ctw-grid-cols-1 sm:ctw-grid-cols-3 gap-4 ctw-p-5 sm:ctw-p-0 ctw-w-52 ctw-w-full ctw-border sm:ctw-border-0 ctw-border-solid ctw-rounded sm:ctw-rounded-none ctw-border-gray-300 sm:ctw-border-transparent">
                    <div class="ctw-col-span-1 sm:ctw-w-52">
                        <img *ngIf="['visa','mastercard','discover'].includes(card.Type)" 
                            class="credit-card" [src]="'assets/img/payment-icons/'+card.Type+'.svg'" />
                        <img *ngIf="['american-express'].includes(card.Type)" 
                            class="credit-card" [src]="'assets/img/payment-icons/amex.svg'" />
                        <span data-private>{{ card.Number}}</span>
                    </div>
                    <div class="ctw-col-span-1 sm:ctw-w-52">{{ trimText(card.Name) }}</div>
                    <div class="ctw-col-span-1 sm:ctw-text-right" data-private>{{ card.ExpirationDate }}</div>
                </div>
            </mat-radio-button>
        </ng-container>
        <mat-radio-button class="ctw-pb-2 ctw-mr-5" value="PayPal" *ngIf="this.allowPaypal">
            PayPal
        </mat-radio-button>
        <mat-radio-button class="ctw-pb-2" value="Check" *ngIf="this.allowCheck">
            Check
        </mat-radio-button>
    </mat-radio-group>
    <div class="ctw-mb-3 ctw-mt-2 ctw-mb-6">
        <a color="primary" *ngIf="this.ampOpApiService.isAuthenticated()"
            (click)="displayAddNewCardDialog()">Add New Card
        </a>
    </div>
    <div [class]="(this.allowCheck === true || this.allowPaypal === true) ? 'ctw-mt-2' : ''" class="ctw-w-full">
        <amp-cc #creditcard *ngIf="this.cardId === 'new' && !this.ampOpApiService.isAuthenticated()"></amp-cc>
    </div>
</div>