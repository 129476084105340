import { Component, Input, OnInit } from '@angular/core';

import { AppService } from '../../../../../app.service';

@Component({
	selector: 'post-purchase',
	templateUrl: 'post-purchase.component.html',
	styleUrls: ['post-purchase.component.scss'],
})
export class PostPurchaseComponent implements OnInit {

	public config: any;

	@Input() public account: any;
	@Input() public project: any;
	@Input() public receipt: any[] = [];
	@Input() public products: any[] = [];
	@Input() public paymentPage = true;
	@Input() public team: any;
	@Input() public checkoutMode = 'default';

	public constructor(
		private appService: AppService,
	) {}

	ngOnInit(): void {
		this.appService.getFirstPartyDomains(false).subscribe();
	}

	public getPurchaseRoute() {
		let returnUrl = '/';
		this.products.forEach((item) => {
			switch (item.label) {
				case 'Domain Registration':
					returnUrl = '/domains/manage';
					break;
				case 'Email Account':
					returnUrl = '/email';
					break;
				case 'Connected Website - Monthly':
					returnUrl = '/projects/' + this.project;
					break;
				case 'Connected Website - Yearly':
					returnUrl = '/projects/' + this.project;
					break;
			}
		});

		return returnUrl;
	}
}
