<dash-card icon="badge" cardTitle="Professional Services" class="dash-card dash-card-mh ctw-mb-8" disabled="true"
	[transparent]="true">
	<ng-container subTitleHTML *ngIf="this.clarityMinutes !== undefined">
		<span class="sub-title ctw-text-base">
			<span class="{{
				this.clarityMinutes > 0
					? 'ctw-text-green-700'
					: 'ctw-text-red-700'
			}}" *ngIf="this.clarityMinutes !== -1">
				{{ this.clarityHours }} Hour<span *ngIf="this.clarityHours > 1 || this.clarityHours === 0">s</span><span
					*ngIf="(this.clarityMinutes % 60) > 0"> {{ (this.clarityMinutes % 60) }} Minutes</span> Available
			</span>
			<span class="ctw-text-red-700" *ngIf="this.clarityMinutes === -1">0 Hours Remaining</span>
		</span>
	</ng-container>
	<mat-progress-bar *ngIf="this.clarityMinutes === undefined" mode="indeterminate" color="primary"></mat-progress-bar>
	<div class="dash-card-content" *ngIf="this.clarityMinutes !== undefined">
		<div class="">
			<span *ngIf="0">Platform i's Professional Services Team is dedicated to providing you with the solutions and
				support that
				you need to succeed. We can assist with general consultation, troubleshooting common WordPress issues,
				website migrations, custom CDN, DNS, or SSL setups, and more.
				<br><br>
				Our Professional Services Team's hours of operation are Monday through Friday, 9 AM to 9 PM Eastern Time
				and excluding major U.S. holidays. Let us help you get your project off to a great start!
			</span>
		</div>
		<p class="ctw-py-4">
			<mat-card appearance="outlined">
				<mat-card-content class="ctw-flex ctw-text-imh-gray-200">
					<mat-icon class="ctw-mr-1">info</mat-icon>
					<i class="ctw-w-full">Our Professional Services Team's hours of operation are Monday through Friday,
						9 AM to 9 PM Eastern Time and excluding major U.S. holidays. Let us help you get your project
						off to a great start!</i>
				</mat-card-content>
			</mat-card>
		</p>
		<div class="ctw-mt-4 ctw-text-right">
			<button mat-button mat-raised-button color="primary"
				(click)="this.openDialog({department: 'managed_hosting'})">
				<span *ngIf="this.clarityMinutes > 0">Get Help</span>
				<span *ngIf="this.clarityMinutes <= 0">Submit a Quote Request</span>
			</button>
			<!--<central-inline-purchase productCode="launch_assist"></central-inline-purchase>-->
		</div>
	</div>
</dash-card>
