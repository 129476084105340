import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { AuthService, ProfileService, BrandingService, TrackingService } from '@central/ng-shared';

import { AppService } from './app.service';
import { MetaService } from './shared/services/meta.service';
import { TourService } from './shared/tours/tour.service';

@Component({
	selector: 'sd-app',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

	public defaultLanguage = 'en';
	public dismissedNag = false;
	public disableGuestView = false;
	public forceGuestView = false;
	public hideSideBar = false;

	constructor(
		public authService: AuthService,
		public profileService: ProfileService,
		public brandingService: BrandingService,
		private router: Router,
		private location: Location,
		private route: ActivatedRoute,
		private trackingService: TrackingService,
		private cookieService: CookieService,
		private appService: AppService,
		private metaService: MetaService,
		private tourService: TourService,
	) {
		this.iframeNavigation();
		this.storeReferrer();
	}

	ngOnInit() {
		if (this.location.path().match('(readme|amp\/checkout)')) {
			this.hideSideBar = true;
		}

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (event.url.endsWith('beta')) {
					this.disableGuestView = true;
				}
				this.metaService.navigationChange(event);
				this.tourService.fetchTours();
			}
		});

		this.handleCookies();
		this.trackingService.loadRouting();

		this.appService.getOffers().subscribe();
		this.appService.sessionStorage.removeItem('project_id');
	}

	public displayAuthView() {
		return true !== this.forceGuestView && 'user' === this.authService.role;
	}

	private async handleCookies() {
		// Used for AMP <-> Central reference
		this.authService.onLogin.subscribe(() => {
			const ttl = new Date();
			ttl.setTime(
				ttl.getTime() + 7776000004 /* 90 days in milliseconds */
			);
			this.cookieService.set('central', 'true', {
				path: '/',
				domain: '.inmotionhosting.com',
				expires: ttl,
			});
		});

		// Used for preferred language inheritance MKTG Site -> Central
		if (this.cookieService.get('lang')) {
			this.defaultLanguage = this.cookieService.get('lang');
		}

		const mktgCookie = this.cookieService.get('mktgp');
		const iridCookie = this.cookieService.get('irid');
		const mktgData = {
			url: document.referrer,
			irid: iridCookie,
		}
		await new Promise(resolve => {
			const pairs = mktgCookie.split(',');
			pairs.forEach((item, index) => {
				mktgData[item.split(':')[0]] = item.split(':')[1];
				if (index === pairs.length - 1) {
					resolve(mktgData);
				}
			})
		})
		this.appService.sessionStorage.setItem('mktgData', mktgData);

		this.trackSalesperson();
	}

	private trackSalesperson() {
		// check for salesperson ID query parameter ('spid') and set cookie if not otherwise found
		const salespersonId = new URL(document.location.href).searchParams.get(
			'spid'
		);
		if (
			salespersonId &&
			/[a-z]+/i.test(salespersonId)
		) {
			const value = {
				salesperson: salespersonId.toLowerCase(),
				created_at: new Date().toISOString(),
			};

			const expires = new Date();
			expires.setTime(
				expires.getTime() + 7776000000 /* 90 days in milliseconds */
			);
			this.cookieService.set('spt', JSON.stringify(value), expires, '/');
		}
	}

	private storeReferrer() {
		this.appService.sessionStorage.setItem('referrer', document.referrer);
		this.route.queryParams.subscribe((params) => {
			if (params['ref']) {
				this.appService.sessionStorage.setItem('ref', params['ref']);
			}
		});
	}

	/**
	 * When the user navigates to a new page while within the iframe. Break out of the iframe.
	 *
	 * @since 1.15.1
	 */
	private iframeNavigation() {
		let breakoutAttempted = false;
		this.router.events
			.pipe(filter((event: any) => event instanceof NavigationStart))
			.subscribe((event: any) => {
				// If inside of an iframe and navigation leads away from page, new tab.
				if (
					event.id === 2 &&
					!breakoutAttempted &&
					window.location !== window.parent.location
				) {
					breakoutAttempted = true;
					const url = this.location.prepareExternalUrl(event.url);
					const win = window.open(url, '_blank');
					if (win) {
						win.focus();
					}

					window.location.href = window.location.href;
				}
			});
	}
}
