import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AmpOpApiService } from '../amp-op-api/amp-op-api.service';
import { finalize } from 'rxjs/operators';

interface Package {
    type?: string;
    productId?: string;
    configs?: any[];
    info?: {
        ProductType: string;
    };
}

interface Category {
    type: string;
    label: string;
    configs: any[];
    selected: any;
    hide: boolean;
}

interface CategoryConfig {
    ProductName: string;
    ProductType: string;
    AddonDetailsId: string;
    LegacyName: string;
    AddonAutoSelected?: boolean;
    [key: string]: any;
}

@Component({
	selector: 'amp-config',
	templateUrl: 'amp-config.component.html',
})
export class AmpConfigComponent {
	@Input() public packages: Package[] = [];
	@Input() public configs: Category[] = [];
	@Input() public categories: Category[] = [];
    public ampCatalog: any;
    public selectedPackage: Package = {};
    public selectedCartInfo = {};
    public selectedAddons = [];
    public selected = [];
    public availableAddonArray = [];
    public availableAddonIds = [];
    public forceCatalogUpdate = false;
    public loading = false;
    public freeCss = "ctw-text-xs ctw-font-bold ctw-bg-green-500 ctw-rounded ctw-m-1 ctw-h-5 ctw-w-10 ctw-p-1 ctw-text-white ctw-text-center";

	@Output() public configChanged = new EventEmitter();
	@Output() public configComplete = new EventEmitter();
	@Output() public configureError = new EventEmitter();

	public constructor(public ampOpApiService: AmpOpApiService) {}

    /**
     * Initializes the categories array based on configuration categories from the AMP API service.
     * Creates a new category object for each category name with default values.
     */
    public setupCategories() {
        const categoryNames = this.ampOpApiService.configurationCategories;
        this.categories = [];
        categoryNames.forEach(name => {
            this.categories.push({type: name, label: name, configs: [], selected: {}, hide: false})
        })
    }

    /**
     * Initializes the component by fetching the AMP catalog and setting up configurations
     * @param update - Whether to trigger a configuration update event (default: true)
     */
    public setup(update = true) {
        this.loading = true;
        this.ampOpApiService.fetchAmpCatalog(null, this.forceCatalogUpdate)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe({
                next: (catalog) => {
                    if(catalog.hasOwnProperty('data')) {
                        this.ampCatalog = catalog;
                        this.selectedPackage = this.packages.filter(e => e.type === 'hosting')[0];
                        this.selectedCartInfo = catalog['data'].find(e=> e.Id === this.selectedPackage['productId'])
                        this.setupCategories();
                        if(this.selectedCartInfo === undefined) {
                            this.forceCatalogUpdate = true;
                            this.setup(update);
                        } else {
                            if(this.forceCatalogUpdate === true) {
                                this.forceCatalogUpdate = false;
                            }
                            const preSelected = this.packages.filter(e => this.categories.map(c => c.type).includes(e.info.ProductType.replace('Addon:','')))
                            const preSelectedIds = preSelected.map(p => p.productId)
                            const selected = [];
                            if(this.selectedPackage.hasOwnProperty('configs')) {
                                this.selectedPackage?.configs?.forEach(c => {
                                    if(preSelectedIds.includes(c.AddonDetailsId)) {
                                        selected.push(c)
                                    }
                                })
                            }

                            this.selected = selected
                            this.updateConfiguration(update,selected)
                        }
                    } else {
                        if(this.forceCatalogUpdate === false) {
                            this.forceCatalogUpdate = true;
                            this.setup(update);
                        } else {
                            this.configureError.emit()
                        }
                    }
                },
                error: (error) => {
                    this.configureError.emit(error);
                }
            });
    }

    /**
     * Gets the selected configurations from either the provided array or currently selected configs
     * @param selected - Optional array of selected configurations
     * @returns Array of selected configurations
     * @private
     */
    private getSelectedConfigs(selected: any[] | null): any[] {
        this.selected = selected ?? this.configs.filter(e => e.selected).map(e => e.selected);
        return this.selected;
    }

    /**
     * Processes configurations for a specific category
     * @param category - The category to process
     * @param selected - Array of selected configurations
     * @param availableAddonIds - Array of available addon IDs
     * @private
     */
    private processCategoryConfigs(category: Category, selected: any[], availableAddonIds: string[]): void {
        let catConfigs = this.selectedPackage?.configs?.filter(config => 
            config?.ProductType === `Addon:${category.type}` && 
            (availableAddonIds.length === 0 || 
            (config?.AddonDetailsId && availableAddonIds.includes(config.AddonDetailsId)))
        ) || [];

        const catalogConfigs = Object.values(this.selectedCartInfo?.['Addon'] ?? {}).filter(e => 
            (e as { Id: number; Priority: number; FormattedType: string; SelectionRequired: boolean, Required: boolean })
            .FormattedType === `Addon:${category.type}`
        );
        catConfigs.forEach(e => {
            const catalogConfig = catalogConfigs.find(c => 
                (c as { Id: number }).Id === e.AddonDetailsId
            );
            if (catalogConfig) {
                e.Priority = (catalogConfig as { Priority: number }).Priority;
                e.DisplayProductDescription = (catalogConfig as { DisplayProductDescription: string }).DisplayProductDescription;
                if(e.DisplayProductDescription === "full") {
                    e.Description = (catalogConfig as { Description: string }).Description;
                    e.ProductName = (catalogConfig as { ProductName: string }).ProductName;
                }
                
            }
        });

        const selectionRequired = catalogConfigs.filter(e => (e as { SelectionRequired: boolean }).SelectionRequired).length > 0;
        const conditionallyRequired = catalogConfigs.filter(e => this.isConditionallyRequired(e)).length > 0;
        const addonIncluded = this.getHasAddonIncluded(catConfigs)
        
        if(catConfigs.length > 0 && selectionRequired === false && conditionallyRequired === false && addonIncluded === false) {
            if(catConfigs.filter(e => e.ProductName === 'No Thanks' && (e.FormattedType === `Addon:${category.type}` || e.ProductType === `Addon:${category.type}`)).length === 0) {
                catConfigs.push({FormattedType: `Addon:${category.type}`, ProductType: `Addon:${category.type}`, ProductName: 'No Thanks'})
            }
        } else if (catConfigs.filter(e => e.ProductName === 'No Thanks').length > 0) {
            catConfigs = catConfigs.filter(e => e.ProductName !== 'No Thanks')
        }
        if (catConfigs.length === 0 || (catConfigs.length === 1 && catConfigs[0].AddonIncluded)) {
            category.hide = true;
        } else {
            category.hide = false;
        }

        category.configs = catConfigs;
        
        this.setSelectedConfig(category, selected, catConfigs);

        if(category.type === 'Control Panel Options') {
            category.configs.sort((a, b) => (a.LegacyName < b.LegacyName ? -1 : 1));
        } else {
            category.configs.sort((a, b) => (parseFloat(a.Priority) < parseFloat(b.Priority) ? -1 : 1));
        }
    }

    /**
     * Checks if an addon is conditionally required based on the current cart state
     * @param addon - The addon configuration to check
     * @returns {boolean} True if the addon is conditionally required, false otherwise
     * @private
     */
    private isConditionallyRequired(addon: any) {
        return addon?.Conditional && this.ampOpApiService.isConditionallyRequired(this.selectedCartInfo, addon, this.selected);
    }

    /**
     * Sets the selected configuration for a category based on various conditions
     * @param category - The category to set the configuration for
     * @param selected - Array of selected configurations
     * @param catConfigs - Array of category configurations
     * @private
     */
    private setSelectedConfig(category: Category, selected: any[], catConfigs: CategoryConfig[]): void {
        const pkgSelected = selected.find(e => e.ProductType === `Addon:${category.type}`);
        if (pkgSelected) {
            const selectedConfig = catConfigs.find(e => (e.hasOwnProperty('AddonDetailsId') && e.AddonDetailsId === pkgSelected.AddonDetailsId) || (e.hasOwnProperty('ProductName') && e.ProductName === pkgSelected.ProductName));
            if(selectedConfig) {
                category.selected = selectedConfig;
                return;
            }
        }

        // Find auto-selected or conditionally required config
        catConfigs.some(config => {
            const addon = this.availableAddonArray.find(a => a.Id === config.AddonDetailsId);
            if (this.isConditionallyRequired(addon) || config.AddonAutoSelected || config.ProductName === 'No Thanks') {
                category.selected = config;
                return true;
            }
            return false;
        });
    }

    /**
	 * Retrieves the description for a given category
	 * @param category - The category to get the description for
	 * @returns The description text if found and DisplayProductDescription is "full",
	 *          otherwise returns an empty string
	 */
    public getDescription(category: Category) {
        return category.configs.find(e => e.hasOwnProperty('Description') && e.DisplayProductDescription === "full")?.Description || '';
    }

    /**
	 * Handles configuration changes with a slight delay to ensure state updates
	 * have completed
	 */
    public handleConfigurationChange() {
        setTimeout(() => {
            this.updateConfiguration()
        }, 100)
    }

    /**
     * Updates the configuration state and emits changes if required
     * @param update - Whether to emit configuration changes
     * @param selected - Optional array of selected configurations
     */
    public updateConfiguration(update = true, selected: any[] | null = null): void {
        const selectedConfigs = this.getSelectedConfigs(selected);
        // Update available addons
        this.availableAddonArray = this.ampOpApiService.getAvailableAddons(this.selectedCartInfo, selectedConfigs);
        this.availableAddonIds = this.availableAddonArray.map(e => e.Id);

        // Process categories
        const newCategories = JSON.parse(JSON.stringify(this.categories));
        newCategories.forEach(category => {
            this.processCategoryConfigs(category, selectedConfigs, this.availableAddonIds);
        });

        // Update component state
        this.selectedAddons = newCategories
            .filter(cat => cat.selected)
            .map(cat => cat.selected);
        this.configs = newCategories.filter(cat => !cat.hide);
        if (update) {
            this.configChanged.emit(newCategories);
        }
    }

    /**
     * Emits the configuration complete event
     */
    public configSet() {
        this.configComplete.emit();
    }

    /**
     * Checks if a configuration is selected for a given category
     * @param config - The configuration to check
     * @param category - The category to check against
     * @returns Boolean indicating if the configuration is selected
     */
    public isSelected(config, category) {
        if(category.selected !== undefined && category.selected.hasOwnProperty('AddonDetailsId')) {
            return config.AddonDetailsId === category.selected.AddonDetailsId;
        }
        if(category.selected !== undefined && category.selected.hasOwnProperty('ProductName')) {
            return config.ProductName === category.selected.ProductName;
        }
        return false;
    }

    /**
     * Generates the price display string for a configuration
     * @param config - The configuration to show the price for
     * @param category - The category of the configuration
     * @returns Formatted price string with HTML
     */
    public showPrice(config, category) {
        if(config.ProductName === 'No Thanks') {
            return '';
        }
        const pricedConfigs = ['Operating System'];
        if (pricedConfigs.includes(config.ProductType.replace('Addon:', ''))) {
            return '';
        }

        const formatPrice = (value: number): string => 
            value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        const price = parseFloat(config.DiscountPrice);
        if (price <= 0) {
            return ' + free';
        }

        const termLength = config.TermType === 'year' 
            ? parseInt(config.TermLength, 10) * 12 
            : parseInt(config.TermLength, 10);

        const perMonth = termLength > 1 
            ? `<span class="ctw-text-xs"> ($${formatPrice(price / termLength)} /mo) </span>`
            : '';
        
        return ` + $${formatPrice(price)}${perMonth}`;
    }

    /**
     * Checks if any configuration in the provided list has an addon included
     * @param configs - Array of configurations to check
     * @returns Boolean indicating if any configuration has an addon included
     */
    public getHasAddonIncluded(configs) {
        return configs.filter(e => e.AddonIncluded).length > 0;
    }
}
