// Angular Modules.
import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout'; // layout module to enable breakpoint observer

// 3rd Party.
import { RecaptchaModule } from 'ng-recaptcha';
import { provideMatomo } from 'ngx-matomo-client';
import { CookieService } from 'ngx-cookie-service';
import { MomentModule } from 'ngx-moment';

// App Modules.
import { AppRoutingModule } from './app.routes';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './shared/material/material.module';
import { GuestModule } from './guest/guest.module';
import { GuideModule } from './guide/guide.module';
import { CheckoutModule } from './checkout/checkout.module';
import { ProjectModule } from './project/project.module';
import { DomainModule } from './domain/domain.module';
import { EmailModule } from './email/email.module';
import { TeamModule } from './team/team.module';
import { BillingModule } from './team/billing/billing.module';
import { InvoiceModule } from './team/billing/invoice/invoice.module';
import { SubscriptionModule } from './team/billing/subscriptions/subscription.module';
import { AmpModule } from './amp/amp.module';
import { TrackingModule } from '@central/ng-shared'

// App Services.
import { AppService } from './app.service';
import { LicenseService } from './shared/license/license.service';
import {
	AuthenticationModule as NgSharedAuth,
	ApiService,
	CoreModule,
	AuthService,
	ProfileService,
	TrackingService,
	FirebaseService,
	AccountModule as SharedAccountModule,
	ApiCacheService,
	SocketModule,
	ZendeskService,
	LogrocketService,
	RollbarService,
	RollbarErrorHandler,
    MatomoAnalyticsService,
	FingerprintService,
	CoreServicesModule,
} from '@central/ng-shared';
import { MetaService } from './shared/services/meta.service';
import { TourService } from './shared/tours/tour.service';

// Components
import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DialogComponent } from './shared/dialog/dialog.component';

// Configs
import { environment } from '../environments/environment';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		RecaptchaModule,
		BrowserModule,
		BrowserAnimationsModule,
		LayoutModule,
		MomentModule,
		// Configs are lost during rollup if saved in base configs. Added inline here.
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAuthModule,
		/**
		 * In order to start the Service Worker in Production located at "/ngsw-worker.js"
		 * uncomment this line. More about Service Workers here
		 * https://developer.mozilla.org/en-US/docs/Web/API/Service_Worker_API/Using_Service_Workers
		 */
		// ServiceWorkerModule.register('ngsw-worker.js', { enabled: String('<%= BUILD_TYPE %>') === 'prod' })

		CoreModule,
		CoreServicesModule.forRoot(environment),
		NgSharedAuth,
		SocketModule,
		SharedAccountModule,
		AppRoutingModule,
		SharedModule.forRoot(),
		MaterialModule,
		GuestModule,
		GuideModule,
		CheckoutModule,
		ProjectModule,
		DomainModule,
		EmailModule,
		TeamModule,
		BillingModule,
		InvoiceModule,
		SubscriptionModule,
		AmpModule,
        TrackingModule,
	],
	exports: [MaterialModule, SharedModule],
	declarations: [AppComponent, SidebarComponent, DialogComponent],
	providers: [
		AppService,
		MetaService,
		TourService,
		ApiService,
		ApiCacheService,
		AuthService,
		ProfileService,
		FirebaseService,
		LicenseService,
		CookieService,
		ZendeskService,
		FingerprintService,
		RollbarService,
		{
			provide: ErrorHandler,
			useClass: RollbarErrorHandler,
		},
		LogrocketService,
		MatomoAnalyticsService,
		provideMatomo({
			...environment.matomoAnalytics.provider
		}),
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
