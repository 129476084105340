import { Injectable } from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';

/**
 * Interface representing the response from the network validation API.
 * @interface NetworkValidationResponse
 * @property {boolean} [public] - Indicates if the network is public.
 * @property {string} [ip] - The IP address of the current network.
 */
interface NetworkValidationResponse {
	public?: boolean;
	ip?: string;
}

/**
 * Service responsible for validating network status and managing network-related information.
 * Provides observables for network status and IP address updates.
 */
@Injectable({
	providedIn: 'root'
})
export class NetworkValidationService {
	private readonly networkStatusSubject = new BehaviorSubject<boolean>(true);
	private readonly ipAddressSubject = new BehaviorSubject<string>('');

	/**
	 * Observable stream of the current network's public/private status.
	 */
	public readonly publicNetwork$ = this.networkStatusSubject.asObservable();

	/**
	 * Observable stream of the current network's IP address.
	 */
	public readonly ipAddress$ = this.ipAddressSubject.asObservable();

	constructor(private readonly apiService: ApiService) {
		this.initializeNetworkValidation();
	}

	/**
	 * Initializes network validation if not already cached.
	 * @public
	 */
	public initializeNetworkValidation() {
		this.apiService
			.get('/v1/ownership-service/validate-network', {})
			.subscribe(response => {
				if ('public' in response) {
					const publicStatus = response.public as boolean ?? true;
					this.networkStatusSubject.next(publicStatus);
				}
				if ('ip' in response) {
					const ipAddress = response.ip as string ?? '';
					this.ipAddressSubject.next(ipAddress);
				}
			},
			(error) => {
				console.error('Network validation failed:', error);
				throw error;
			})
	}
}
